<template>
  <div class="v-home" v-scroll:#scroll-target="onScroll">
    <section class="v-home__head">
      <div class="v-home__head-title">
        ОТМЕНИМ<br>СУДЕБНЫЙ ПРИКАЗ
        <p>ГАРАНТИЯ 100% ИЛИ ВОЗВРАТ ДЕНЕГ</p>
      </div>
    </section>
    <section class="effects">
      <v-row class="effects__wrap">
        <v-col cols="3">
          <img src="../assets/img/many.svg" alt=""><p>Фиксированная<br>стоимость услуги<br>
          1500 рублей</p>
        </v-col>
        <div><svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.201843" y="19.8046" width="25.0869" height="25.0869" transform="rotate(-48.9569 0.201843 19.8046)" fill="#C3AD8F" fill-opacity="0.5"/>
        </svg>
        </div>
        <v-col cols="3">
          <img src="../assets/img/surface.svg" alt=""><p>Гарантия полной<br>отмены даже при<br>пропуске срока</p>
        </v-col>
        <div><svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.201843" y="19.8046" width="25.0869" height="25.0869" transform="rotate(-48.9569 0.201843 19.8046)" fill="#C3AD8F" fill-opacity="0.5"/>
        </svg>
        </div>
        <v-col cols="3">
          <img src="../assets/img/surface1.svg" alt=""><p>Бесплатная<br>индивидуальная<br>консультация</p>
        </v-col>
      </v-row>
    </section>
    <section class="step">
      <div id="ya3" class="step__title">КАК ЭТО РАБОТАЕТ</div>
      <div class="step__line"></div>
      <div class="step__wrap">
        <div class="step1">Присылаете судебный приказ или его данные <span class="step-circle1">1</span></div>
      </div>
      <div class="step__wrap">
        <div class="step2">Получаете от юриста необходимые документы <span class="step-circle2">2</span></div>
      </div>
      <div class="step__wrap">
        <div class="step3">Отправляете их почтой и отменяете приказ <span class="step-circle3">3</span></div>
      </div>
    </section>
    <section class="consult">
      <div class="consult__title" id="ya1">Оставьте заявку и юрист свяжется с вами</div>
      <div class="v-home__forms">
        <v-form
                ref="form"
                v-model="valid"
                class="d-flex justify-space-around v-home__forms-wrap"
        >
          <v-text-field name="name" v-model="form.name" dark :rules="rules" class="v-home__forms-input" placeholder="Ваше имя"></v-text-field>
          <v-text-field name="email" v-model="form.email" dark class="v-home__forms-input" placeholder="Почта"></v-text-field>
          <v-text-field name="tel" title="Формат: 89999999999" v-model="form.phone" dark :rules="rules1" class="v-home__forms-input" placeholder="Телефон" ></v-text-field>
        </v-form>
        <div class="btn-wrapper">
          <v-btn
                  :disabled="!valid"
                  color="rgb(255 247 0)"
                  class="mr-4 v-home__forms-btn"
                  @click="send"
                  dark
                  @mouseover="vAlert = true"
                  @mouseleave="vAlert = false"
          >
            <img src="../assets/img/icons/phone-in-talk.png" alt="">
            Позвонить мне
          </v-btn>
        </div>
      </div>
    </section>
    <section id="ya4" class="example">
      <div class="example__title"></div>
      <div class="example__wrap">
        <div class="example__container">
          <img src="../assets/img/foto1.png" alt="">
          <div class="example__text">
            <span>ДОГОВОР</span>
            Судебный приказ вынесен по договору
            физлица и ООО. Сумма взыскания 176000 руб.
            Приказ был отменен, истец не подал новый иск
          </div>
        </div>
        <div class="example__container">
          <img src="../assets/img/foto2.png" alt="">
          <div class="example__text">
            <span>ЖКХ</span>
            Заявления на судебные приказы по ЖКХ
            распространенная практика. Мы отменяем их
            по несколько тысяч в месяц
          </div>
        </div>
        <div class="example__container">
          <img src="../assets/img/foto3.png" alt="">
          <div class="example__text">
            <span>СПОР С ИФНС</span>
            Налоговая добилась вынесения
            приказа и нам удалось отменить
            его.
            Прошло полгода, нового иска нет,
            а срок исковой давности скоро истечет
          </div>
        </div>
        <div class="example__container">
          <img src="../assets/img/foto4.png" alt="">
          <div class="example__text">
            <span>ПРИСТАВЫ</span>
            Дело было передано приставам. Сроки
            пропущены. Юристы восстановили сроки
            и отменили приказ. Приставы приостановили
            процедуру взыскания
          </div>
        </div>
      </div>
    </section>
    <section class="why">
      <div class="why__title-block">
        Почему важно отменять приказ
        <img src="../assets/img/warn.svg" alt="">
      </div>
      <div class="why__wrap">
        <div class="why__container">
          <div class="why__circle">1</div>
          <p>Как показывает практика, 65% исковых заявлений не подается в суд после отмены приказа.
            Причина проста. С юридической точки зрения, подготовка комплекта документов и участие в
            суде зачастую либо затратно либоневозможно.
            Поэтому отменив приказ, Вы получаете возможность вовсе избавиться от долга</p>
        </div>
        <div class="why__container">
          <div class="why__circle">2</div>
          <p>За время отмены и подачи нового искового заявления у Вас появляется возможность для маневров</p>
        </div>
      </div>
    </section>
    <section class="consult" style="border: 1px solid #b16f6f;border-radius: 10px">
      <div class="consult__title" style="background: initial" id="ya2">ЮРИСТ <span style="color: #C4AD92">БЕСПЛАТНО</span>
        ПРОКОНСУЛЬТИРУЕТ ИМЕННО ПО ВАШЕМУ ДЕЛУ</div>
      <div class="v-home__forms">
        <v-form
                ref="form"
                v-model="valid"
                class="d-flex justify-space-around v-home__forms-wrap"
        >
          <v-text-field name="name" v-model="form.name" dark :rules="rules" class="v-home__forms-input" placeholder="Ваше имя"></v-text-field>
          <v-text-field name="email" v-model="form.email" dark class="v-home__forms-input" placeholder="Почта"></v-text-field>
          <v-text-field name="tel" title="Формат: 89999999999" v-model="form.phone" dark :rules="rules1" class="v-home__forms-input" placeholder="Телефон" ></v-text-field>
        </v-form>
        <div class="btn-wrapper">
          <v-btn
                  :disabled="!valid"
                  color="rgb(255 247 0)"
                  class="mr-4 v-home__forms-btn"
                  @click="send"
                  dark
                  @mouseover="vAlert = true"
                  @mouseleave="vAlert = false"
          >
            <img src="../assets/img/icons/phone-in-talk.png" alt="">
            Позвонить мне
          </v-btn>
        </div>
      </div>
    </section>
    <Dialog
            :dialog="dialog.isDialog"
            :success:="dialog.success"
            :title="dialog.title"
            :subtitle="dialog.subtitle"
            @closeDialog="closeDialog"
            class="popup"
    />
  </div>
</template>

<script>
  import axios from 'axios'
  import Dialog from './dialog'
  export default {
    name: 'Home',
    components: { Dialog },
    data: () => ({
      vAlert: false,
      valid: null,
      form: {
        name: null,
        email: null,
        phone: null,
        detail: null,
      },
      dialog: {
        isDialog: null,
        title: '',
        subtitle: '',
        success: null,
      },
      offsetTop: 0,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      rules: [
        value => !!value || 'Обязательное поле',
        value => (value && value.length >= 3) || 'Не менее 3 символов',
      ],
      rules1: [
        value => !!value || 'Обязательное поле',
        value => (typeof value === "string") || 'Введите только цифры',
        value => (value && value.length >= 11) || 'Не менее 11 цифр',
        value => (value && value.length <= 11) || 'Не более 11 цифр',
      ],
    }),
    methods: {
      onScroll (e) {
        this.offsetTop = e.target.scrollTop
      },
      send (e) {
        if (this.valid) {
          if (this.form.phone) {
            console.log(e)
            const formData = new FormData()
            formData.append('crm', '16')
            formData.append('pipe', '34')
            formData.append('contact[name]', this.form.name)
            formData.append('contact[466]', this.form.phone)
            formData.append('contact[467]', this.form.email)
            // formData.append('lead[214]', 'ЕБСА')
            // formData.append('note', 'Заявка с ЕБСА по статье ' + this.numArticle + '<br>' + this.result.join())
            // для mychance.avtourist.info
            formData.append('lead[541]', 'суд.мфюц.рф')
            formData.append('note', 'Заявка с суд.мфюц.рф <br>' + (this.form.detail ? 'Детали: ' +  this.form.detail : 'Детали не указаны')) // удаление запятых из this.result
            console.log(Boolean(this.form.detail))
            axios.post('https://bez.v-avtoservice.com/api/import-leadd',
                    formData,
                    {
                      headers: {
                        'Content-Type': 'multipart/form-data'
                      }
                    }
            ).then(res => {
              if (res.data.status === 'success') {
                this.dialog.title = 'Отправлено!'
                this.dialog.subtitle = 'Ваше заявка успешно отправлена! Наш специлист свяжется с вам в ближайшие минуты'
                this.dialog.isDialog = true
                this.dialog.success = true
              } else {
                this.dialog.title = 'Ошибка!'
                this.dialog.subtitle = 'Обновите страницу и попробуйте снова'
                this.dialog.isDialog = false
                this.dialog.success = false
              }
            })
          } else {
            this.dialog.isDialog = true
            this.dialog.title = 'Заполните форму'
            this.dialog.subtitle = 'Пожалуйста, заполните поля, чтобы мы могли с вами связаться'
            this.dialog.success = false

          }
        } else this.valid = false
      },
      closeDialog () {
        this.dialog.isDialog = false
      }
    }
  }
</script>
<style lang="scss">
  .v-home {
    padding: 5% 5% 0 5%;
    color: white;
    background: url("../assets/img/back.jpg") no-repeat;
    background-size: contain;
    background-color: #080808;
    overflow-x: hidden;
    .v-home__head {
      height: calc(100vh - 78px);
      display: flex;
      align-items: flex-end;
      .v-home__head-title {
        color: #C3AD8F;
        font-size: 70px;
        font-weight: bold;
        position: relative;
        line-height: 75px;
        p {
          color: white;
          font-size:26px;
        }
      }
    }
    .effects {
      padding: 50px 0;
      .effects__wrap {
        display: flex;
        justify-content: space-around;
        align-items: center;
        text-align: center;
        padding: 30px 0;
        background: #1C1C1C;
        border-radius: 51.1029px;
      }
    }
    .step {
      display: flex;
      flex-direction: column;
      position: relative;
      .step__title {
        margin: 0 auto;
        font-size: 30px;
      }
      .step__line {
        width: 2px;
        height: 320px;
        position: absolute;
        border: 2px dashed gray;
        left: 50%;
        top: 80px;
      }
      .step__wrap {
        div {
          background: rgba(195, 173, 143, 0.7);
          border-radius: 20.2831px;
          display: inline-block;
          padding: 20px 50px 20px 20px;
          position: relative;
          margin: 40px 0;
          margin-left: calc(50% - 422px);
          margin-right: auto;
          span {
            background: #C3AD8F;
            box-shadow: -4.8293px 0.965861px 3.86344px rgba(0, 0, 0, 0.25);
            padding: 20px 35px;
            display: inline-block;
            border-radius: 50px;
            font-size: 30px;
            position: absolute;
            top: -10px;
            right: -50px;
          }
        }
        .step3 {
          margin-left: calc(50% - 404px);
        }
        .step2 {
          margin-left: auto;margin-right: calc(50% - 435px);
          display: table;
          padding: 20px 20px 20px 50px;
          span {
            left: -50px;
            top: -10px;
            right: initial;
            box-shadow: 3px 0px 4px 1px rgb(0 0 0 / 25%);
          }
        }
      }
    }
    .consult {
      display: flex;
      flex-direction: column;
      margin: 40px 0;
      .consult__title {
        color: white;
        margin: 20px auto;
        display: block;
        background: #323232;
        width: 50%;
        text-align: center;
        padding: 10px;
        border-radius: 5px;
        font-size: 30px;
      }
      .v-home__forms {
        width: 80%;
        margin: 0 auto;
        .v-home__forms-input {
          max-width: 20%;
        }
      }
      .btn-wrapper {
        display: flex;
        align-items: center;
        margin: 20px auto;
        justify-content: flex-end;
        position: relative;
        .btn-wrapper-err-mess {
          position: absolute;
          top: -12px;
          right: 220px;
        }
        .v-home__forms-btn {
          color: #080808;
        }
      }
    }
    .example {
      background: #1C1C1C;
      border-radius: 31px;
      padding: 50px;
      .example__title {
        font-size: 50px;
        margin: 10px;
      }
      .example__wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        .example__container {
          position: relative;
          margin: 50px 0;
          width: 100%;
          img {
            position: absolute;
            width: 250px;
            height: 150px;
            transform: translateY(-20px);
            left: 3%;
            z-index: 10;
          }
          .example__text {
            position: relative;
            padding: 20px 20px 20px 70px;
            background: rgba(138, 123, 102, 0.3);
            border-radius: 88.235px;
            width: calc(80%);
            min-width: 80%;
            min-height: 90px;
            left: calc(3% + 190px);
            span {
              position: absolute;
              top: -35px;
              font-size: 25px;
              color: #C3AD8F;
            }
          }
        }
      }
    }
    .why {
      .why__title-block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100px;
        margin: 100px;
        background: #1C1C1C;
        border-radius: 31px;
        font-size: 40px;
        position: relative;
        img {
          position: absolute;
          right: -10px;
          max-width: 150px;
        }
      }
      .why__wrap {
        display: flex;
        flex-direction: column;
        .why__container {
          display: flex;
          align-items: center;
          width: 90%;
          margin: 30px auto;
          .why__circle {
            min-width: 100px;
            height: 100px;
            margin-right: 30px;
            background: #C3AD8F;
            border-radius: 50%;
            box-shadow: -4.8293px 0.965861px 3.86344px rgba(0, 0, 0, 0.25);
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 33px;
          }
        }
      }
    }
    .advokats {
      margin-top: 100px;
      padding: 100px 5%;
      position: relative;
      left: -5.5%;
      width: 100vw;
      background-size: cover;
      .advokats__wrapper {
        .advokats__container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          text-align: center;
          height: 150px;
          background: rgba(0,0,0, 0.9);
          border-radius: 5px;
          .advokats__title {
            margin-top: 20px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .advokats__btn {
            border-top: 1px dashed gray;
          }
        }
      }
      .advokats__case {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: rgba(0,0,0, 0.6);
        border-radius: 10px;
        margin: 50px 0;
        padding: 50px 0;
        font-size: 20px;
        .case-title {
          width: 100%;
          text-align: center;
          font-size: 40px;
        }
        .case-container {
          display: flex;
          align-items: center;
          img { margin-right: 20px; max-width: 120px }
        }
      }
    }
    .know {
      padding: 100px 5%;
      position: relative;
      left: -5.5%;
      width: 100vw;
      background-size: cover;
      .know-wrap {
        background: rgba(0,0,0,0.5);
        padding: 20px;
        .know__title { font-size: 40px; margin: 20px auto; display: block; text-align: center }
        .know__wrapper {
          display: flex;
          justify-content: space-around;
          .know__left {
            display: flex;
            flex-direction: column;
            align-items: center;
            .know__left-container {
              display: flex;
              align-items: center;
              text-align: center;
              justify-content: center;
              margin: 20px;
              height: 130px;
              width: 300px;
              border-radius: 10px;
              border: 1px dashed white;
            }
          }
          .know__right {
            width: 35%;
            .know__right-btn {
              display: inherit;
              width: 200px;
              border: 1px solid white;
              margin-left: auto;
            }
          }
        }
      }
    }
    .popup {
      position: fixed;
      width: auto;
    }
  }
  @media (max-width: 720px) {
    .v-home .consult .consult__title { width: 100%!important; }
    .v-home__forms-wrap {
      flex-direction: column;
      .v-home__forms-input {
        max-width: 100%!important;
        width: 100%;
      }
    }
    .know__wrapper.d-flex {
      flex-wrap: wrap;
      .know__right {
        width: 100% !important;
        .v-home__forms-input {
          max-width: 100%!important;
          width: 100%;
        }
      }
    }
  }
  @media (max-width: 500px) {
    .btn-wrapper {
      .btn-wrapper-err-mess {
        top: 52px !important;
        right: initial !important;
      }
    }
  }
</style>
