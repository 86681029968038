<template>
  <footer class="v-footer d-flex justify-center">
      <div class="d-flex flex-column align-center v-header__container" style="color: white">
        <p>
          Участник проекта
          “Рейтинг Юристов”
        </p>
        <a href="https://reiting-uristov.ru">reiting-uristov.ru</a>
      </div>
  </footer>
</template>

<script>
  export default {
    name: 'Footer',
    data () {
      return {
      }
    },
  }
</script>
<style lang="scss">
  .v-footer {
    padding: 5% !important;
    background: rgb(4,4,4);
    background: linear-gradient(0deg, rgba(4,4,4,1) 0%, rgba(65,65,65,1) 100%);;
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    .v-footer__left {
      flex-basis: 30%;
    }
    .v-footer__right {
      display: flex;
      flex-direction: column;
    }
  }
  @media (max-width: 720px) {
    .v-footer {
      flex-wrap: wrap;
      .v-footer__left {
        flex-basis: 100%;
      }
    }
    @media (max-width: 500px) {
      .v-header__container {
        flex-wrap: wrap;
      }
    }
  }
</style>