<template>
  <header class="v-header">
    <div class="v-header__logo">Юридическая<br>экосистема</div>
    <v-row align="center" justify="center" class="v-header__nav">
      <v-col tag="a" href="#ya3" cols="3">Как это работает</v-col>
      <v-divider dark vertical></v-divider>
      <v-col tag="a" href="#ya4" cols="3">Примеры отмены</v-col>
      <v-divider dark vertical></v-divider>
      <v-col tag="a" href="#ya2" cols="3">Консультация</v-col>
    </v-row>
  </header>
</template>

<script>
  export default {
    name: 'Header',
    data () {
      return {
        tab: false,
      }
    },
    computed: {
      window: () => {
        return window.innerWidth
      }
    }
  }
</script>
<style lang="scss">
  .v-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    color: white;
    display: flex;
    padding: 10px 20px;
    background: rgba(0, 0, 0, 0.3);
    .v-header__logo {
      background: #282828;
      padding: 5px 10px;
      border-radius: 5px;
    }
    .v-header__nav {
      text-align: center;
    }
  }
</style>